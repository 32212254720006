import { Router, RouterLink } from "@angular/router";
import { Component, inject } from "@angular/core";
import { AuthenticationService } from "ti-frontend-shared";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "ti-start",
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.scss"],
  standalone: true,
  imports: [RouterLink, TranslateModule]
})
export class StartComponent {
  private as: AuthenticationService = inject(AuthenticationService);
  private router: Router = inject(Router);
  constructor() {}
}
