import { inject, Injectable } from "@angular/core";
import { AuthenticationService, StorageService } from "ti-frontend-shared";
import { UserContextTO } from "../../shared/generated/transportObjects";
import { UserContextSignalStore } from "../signal-stores/user-context-signal-store";
import { patchState } from "@ngrx/signals";

@Injectable({
  providedIn: "root"
})
export class ApplicationStateService {
  private stepPrefix = "step";
  private storageService = inject(StorageService);
  private authService: AuthenticationService = inject(AuthenticationService);
  userContextStore = inject(UserContextSignalStore);
  constructor() {}

  setInvoiceStep(step: number, obj: any): void {
    this.storageService.setItem("session", { key: this.stepPrefix + step.toString(), value: JSON.stringify(obj) });
  }

  setInvoiceCopy(): void {
    this.storageService.setItem("session", { key: "invoiceCopy", value: "true" });
  }
  getInvoiceCopy(): any {
    return this.storageService.getItem("session", "invoiceCopy");
  }

  getInvoiceStep(step: number): any {
    const obj: any = this.storageService.getItem("session", this.stepPrefix + step.toString());
    if (obj) {
      return JSON.parse(obj);
    } else {
      return null;
    }
  }

  setCurrentUser(currentUser: UserContextTO): void {
    this.storageService.setItem("local", { key: "cms-user", value: JSON.stringify(currentUser) });
    patchState(this.userContextStore, currentUser);
  }

  getCurrentUser(): UserContextTO | undefined {
    const user: string = this.storageService.getItem("local", "cms-user");
    if (user) {
      return JSON.parse(user);
    } else {
      return undefined;
    }
  }

  clearSteps(): void {
    this.storageService.clearStorage("session");
  }

  clearForLogout(): void {
    this.storageService.clearStorage("session");
    this.storageService.clearStorage("local");
    this.authService.logout();
  }
}
