<ng-template #logInView>
  <div class="application-content">
    <mat-toolbar>
      <til-application-toolbar
        sideHeaderWidth="110px"
        applicationName="treeIT CMS"
        [loggedInUser]="fullUserName()"
        (logoClickEvent)="navHome()">
        <button e2e="topnav-btn-customers" routerLink="/customers/welcome" mat-button>{{ "common.customers" | translate }}</button>
        <button e2e="topnav-btn-invoices" routerLink="/invoices/welcome" mat-button>{{ "topNavigation.invoices" | translate }}</button>
        <button e2e="topnav-btn-products" routerLink="/apps/products" mat-button>{{ "common.products" | translate }}</button>
        <button e2e="topnav-btn-stats" routerLink="/statistics/welcome" mat-button>{{ "topNavigation.stats" | translate }}</button>
        <div class="menu" user-menu>
          <button
            e2e="userMenu-btn"
            mat-icon-button
            aria-label="user menu"
            [matMenuTriggerFor]="menu"
            (click)="openMenu()"
            (mouseenter)="openMenu()">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu" overlapTrigger="false">
            <button mat-menu-item e2e="userMenu-btn-userAccount" [matMenuTriggerFor]="langSwitch">
              <mat-icon>account_circle</mat-icon>User Account
            </button>
            <button e2e="userMenu-btn-company" mat-menu-item (click)="getCompany()"><mat-icon> business</mat-icon>Company</button>
            <button e2e="userMenu-btn-logout" mat-menu-item class="logout" (click)="logout()">
              <mat-icon> directions_run</mat-icon>Logout
            </button>
            <mat-divider></mat-divider>
            <div class="app-version">
              <p>V {{ appVersion }}</p>
            </div>
          </mat-menu>
        </div>
        <mat-menu #langSwitch="matMenu" xPosition="before" yPosition="below">
          <button e2e="userMenu-btn-language" mat-menu-item [matMenuTriggerFor]="selectLanguage">Language</button>
        </mat-menu>
        <mat-menu #selectLanguage="matMenu" xPosition="before" yPosition="below">
          <button mat-menu-item (click)="switchLang(language)" *ngFor="let language of translate.getLangs()" class="language">
            <p [ngClass]="{ currentLanguage: language === defaultLang() }">{{ language | langTransform }}</p>
          </button>
        </mat-menu>
      </til-application-toolbar>
    </mat-toolbar>
    <mat-sidenav-container [hasBackdrop]="false" class="sidenav">
      <mat-sidenav mode="side" [(opened)]="sideNavOpened" [fixedInViewport]="false">
        <ti-sidenav></ti-sidenav>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="route-content-container">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-template>

<div *ngIf="!loggedIn; else logInView" class="public-container">
  <router-outlet></router-outlet>
</div>

<ti-spinner *ngIf="loading()"></ti-spinner>
