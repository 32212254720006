import { signalStore, StateSignal, withState } from "@ngrx/signals";
import { UserContextTO } from "../../shared/generated/transportObjects";
import { Type } from "@angular/core";
import { SignalStoreProps } from "@ngrx/signals/src/signal-store-models";
import { Prettify } from "@ngrx/signals/src/ts-helpers";

export const initialValues: UserContextTO = {
  userInformation: { uuid: "", firstName: "", lastName: "" },
  company: {
    companyId: null,
    version: null,
    name: "",
    legalForm: "",
    taxId: "",
    fon: "",
    webAddress: "",
    mailAddress: "",
    address: null,
    bankAccount: null,
    invoiceTemplateName: "",
    enabledFreelancer: false,
    enabledPositionUnits: [],
    defaultTaxInPercent: 19
  },
  token: "",
  preferredLanguage: "en_US"
};

export const UserContextSignalStore: Type<SignalStoreProps<any> & StateSignal<Prettify<any["state"]>>> = signalStore(
  { providedIn: "root" },
  withState(initialValues)
);
